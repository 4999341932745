import { jsx as r } from "react/jsx-runtime";
import { styled as m } from "@linaria/react";
import n from "clsx";
import { useLayoutContext as e } from "./index.es205.js";
/* empty css            */
const u = /* @__PURE__ */ m("div")({
  name: "SLayoutMain",
  class: "SLayoutMain_s2nkvbx",
  propsAsIs: !1
}), L = ({
  children: o,
  className: t,
  ...a
}) => {
  const {
    layoutMainRef: s,
    mobileLayout: i
  } = e();
  return /* @__PURE__ */ r(u, {
    ...a,
    className: n(t, i),
    ref: s,
    "data-test-id": "layout-main",
    children: o
  });
};
export {
  L as LayoutMain,
  u as SLayoutMain
};
